<template>
  <div class="training-page">
    <header class="page-header">
      <PageTitle v-if="training" :item="training" property="title"/>
      <div class="buttons">
        <button v-if="training && checkPermissions(['academy_training.edit'])" class="btn btn-save" @click="saveTraining">
          {{ training && training.id ? 'Training wijzigen' : 'Training toevoegen' }}
        </button>
      </div>
    </header>

    <div class="main-content">
      <formulate-form v-if="training" ref="trainingForm" v-model="training" name="trainingForm">
        <tabs @changed="tabClicked" :options="{ useUrlFragment: false }">
          <tab name="Info">
            <formulate-input #default="{ index }" groupRepeatable-class="translations-group formulate-input-group-repeatable" name="translations" type="group">
              <h3>Vertaling: {{ activeLocalesBack[getLangCode(training, index)] }}</h3>
              <TranslationsRemoveButton :index="index" :item="training"/>

              <formulate-input name="lang_code" type="hidden"></formulate-input>
              <formulate-input label="Titel" name="title" type="text" validation="required"></formulate-input>
              <formulate-input label="Overzicht intro" name="summary" type="tiptap"></formulate-input>
              <formulate-input :validation="'required'" label="Info" name="info" type="tiptap"></formulate-input>
              <formulate-input :validation="'required'" label="Body" name="body" type="tiptap"></formulate-input>
              <formulate-input label="Programma" name="programme" type="tiptap"></formulate-input>
              <formulate-input :validation="'required'" label="Locatie" name="location" type="tiptap"></formulate-input>
            </formulate-input>

            <formulate-input v-if="!fileData" :file-upload-complete="uploadIconComplete()" :file-upload-progress="uploadIconComplete" :upload-url="uploadUrl" :uploader="uploader" error-behavior="submit" label="Afbeelding" name="files" type="file" upload-behavior="live"></formulate-input>
            <div v-if="fileData" class="image-placeholder">
              <img :src="fileData" alt="Test" width="50"/>
              <button v-if="fileData" class="btn btn-remove-file" type="button" @click="removeFileData()">Bestand verwijderen</button>
            </div>
            <formulate-input label="Gepubliceerd" name="published" type="checkbox"></formulate-input>

            <TranslationsAddButtons :item="training"/>
          </tab>

          <tab v-if="training.id" name="Trainings data">
            <div class="trainings-data-header">
              <div>&nbsp;</div>
              <router-link v-if="!isLoading" :to="{ name: 'admin.training.event.add', params: { trainingId: this.trainingId }}" class="btn btn-save">
                <span>{{ 'Trainings data toevoegen' }}</span>
              </router-link>
            </div>

            <div v-if="training.events.length > 0" class="training-events">
              <div class="table-wrapper">
                <div class="table-element">
                  <table class="training-events-table">
                    <thead>
                    <tr>
                      <th class="id">ID</th>
                      <th class="active" :class="filterEventStart ? 'desc' : 'asc'" @click="filterEventStart = !filterEventStart">
                        <div>Van</div>
                      </th>
                      <th>Tot</th>
                      <th>Plaatsen</th>
                      <th>Plaatsen ingenomen</th>
                      <th>Plaatsen vrij</th>
                      <th>Taal</th>
                      <th>Gepubliceerd</th>
                    </tr>
                    </thead>

                    <tbody>
                    <router-link v-for="event in filteredEvents" :key="event.id" :to="{ name: 'admin.training.event', params: { trainingId: trainingId, id: event.id } }" tag="tr">
                      <td class="id">{{ event.id }}</td>
                      <td>{{ event.event_start.setLocale('nl').toFormat('dd/LL/yyyy HH:mm') }}</td>
                      <td>{{ event.event_end.setLocale('nl').toFormat('dd/LL/yyyy HH:mm') }}</td>
                      <td>{{ event.seats }}</td>
                      <td>{{ event.seats_taken }}</td>
                      <td>{{ event.seats - event.seats_taken }}</td>
                      <td>{{ activeLocalesBack[event.language] }}</td>
                      <td>
                        <img v-if="event.published" alt="check" src="@/assets/img/check.svg" width="15">&nbsp;
                      </td>
                    </router-link>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else>
              Er is nog geen trainings data beschikbaar voor deze training.
            </div>
          </tab>

          <tab v-if="training.id" name="Boekingen">
            <div class="trainings-data-header">
              <div>
                <formulate-input v-model="bookingsFrom" name="date_filter" type="date" @change="filterBookings"/>
              </div>
              <button v-if="!isLoading && bookings.length > 0" class="btn btn-download" @click="exportBookings()">
                <span>{{ 'Boekingen exporteren' }}</span>
              </button>
            </div>

            <div v-if="bookings.length > 0" class="training-bookings">
              <div class="table-wrapper">
                <div class="table-element">
                  <table class="training-bookings-table">
                    <thead>
                    <tr>
                      <th class="id">ID</th>
                      <th>Bedrijf</th>
                      <th>Contact naam</th>
                      <th>Contact e-mail</th>
                      <th>Aantal deelnemers</th>
                      <th>Van</th>
                      <th>Tot</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="booking in bookings" :key="booking.id" @click="$router.push({ name: 'admin.training.event.booking', params: { trainingId: trainingId, eventId: booking.event.id, id: booking.id }})" @contextmenu.prevent.stop="bookingRightClick($event, booking)">
                      <td class="id">{{ booking.id }}</td>
                      <td>{{ booking.company_name }}</td>
                      <td>{{ booking.contact_first_name + ' ' + booking.contact_last_name }}</td>
                      <td>{{ booking.contact_email }}</td>
                      <td>{{ booking.participants.length }}</td>
                      <td>{{ datetime.fromISO(booking.event.event_start).setLocale('nl').toFormat('dd/LL/yyyy HH:mm') }}</td>
                      <td>{{ datetime.fromISO(booking.event.event_end).setLocale('nl').toFormat('dd/LL/yyyy HH:mm') }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pagination">
                <div class="results">
                  {{ total.min }} - {{ total.max }} van {{ bookingsTotal }} {{ 'bookingen' }}
                </div>

                <div class="buttons">
                  <button :disabled="page === 0" class="btn-inverse" @click="prevPage()">Prev</button>
                  <button :disabled="bookings.length !== perPage" class="btn-inverse" @click="nextPage()">Next</button>
                </div>
              </div>
            </div>
            <div v-else>
              Er zijn geen bookingen voor de geselecteerde data
            </div>

            <vue-simple-context-menu ref="vueSimpleContextMenu" :options="contextMenuOptions" element-id="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
          </tab>
        </tabs>
      </formulate-form>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import 'vue-select/dist/vue-select.css'
import PageTitle from '@/components/admin/PageTitle'
import { Tab, Tabs } from 'vue-tabs-component'
import { DateTime } from 'luxon'
import TranslationsAddButtons from '@/components/admin/TranslationsAddButtons'
import TranslationsRemoveButton from '@/components/admin/TranslationsRemoveButton'

export default {
  name: 'Training',
  components: {
    PageTitle,
    Tabs,
    Tab,
    TranslationsAddButtons,
    TranslationsRemoveButton
  },
  data: function () {
    return {
      isLoading: true,
      training: null,
      trainingId: this.$route.params.id,
      trainingEvents: [],
      filterEventStart: false,
      bookings: [],
      bookingsTotal: 0,
      page: 0,
      perPage: 25,
      bookingsFrom: DateTime.now().toISODate(),
      datetime: DateTime,
      contextMenuOptions: [{ id: 'deleteBooking', name: 'Booking verwijderen' }],
      fileData: null,
      uploader: ApiService.getAxiosInstance(),
      uploadUrl: '/files/trainings',
      tabClicks: 0
    }
  },
  async mounted () {
    this.isLoading = true

    if (this.trainingId) {
      await this.fetchTraining(this.trainingId)
      await this.filterBookings()

      if (this.training?.file_id) {
        this.fileData = (await ApiService.getFile(this.training.file_id)).data
      }
    } else {
      this.training = {
        published: false,
        translations: [{ lang_code: this.activeLocale }]
      }
    }

    this.isLoading = false
  },
  methods: {
    async tabClicked () {
      this.tabClicks++

      if (this.tabClicks > 1) {
        await this.saveTraining(false)
      }
    },
    async removeFileData () {
      await ApiService.deleteFile(this.training.file_id)
      this.fileData = null
      this.training.file_id = null
    },
    uploadIconComplete: async function () {
      if (this.training?.files?.results?.[0] !== undefined) {
        this.training.file_id = this.training.files.results[0][0]
        this.uploading = false
      }
    },
    bookingRightClick: async function (event, booking) {
      this.$refs.vueSimpleContextMenu.showMenu(event, booking)
    },
    prevPage () {
      this.page--
      this.filterBookings()
    },
    nextPage () {
      this.page++
      this.filterBookings()
    },
    contextOptionClicked: async function (event) {
      await ApiService.deleteTrainingEventBooking(this.trainingId, event.item.event.id, event.item.id).then(async (res) => {
        if (res.status === 200) {
          notification('Booking succesvol verwijderd.')
          await this.filterBookings()
        }
      })
    },
    fetchTraining: async function (id) {
      if (!(this.checkPermissions(['academy_training.create']))) {
        return false
      }

      await ApiService.fetchTraining(id).then(res => {
        this.training = res.data
        if (this.training.events) {
          this.training.events.map(event => {
            event.created = DateTime.fromISO(event.created)
            event.event_start = DateTime.fromISO(event.event_start)
            event.event_end = DateTime.fromISO(event.event_end)
          })
        }
      })
    },
    exportBookings: async function () {
      await ApiService.exportBookings(this.trainingId, {
        from_date: this.bookingsFrom,
        order_by: 'booking.created'
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'boekingen.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    filterBookings: async function () {
      await this.fetchBookings(this.trainingId, {
        from_date: this.bookingsFrom,
        order_by: 'booking.created',
        page: this.page
      })
    },
    fetchBookings: async function (trainingId, data) {
      await ApiService.fetchAllBookings(trainingId, data).then(res => {
        this.bookings = res.data.bookings
        this.bookingsTotal = res.data.total
      })
    },
    saveTraining: async function (showMessage = true) {
      this.isLoading = true

      this.$formulate.submit('trainingForm')
      if (await this.$refs.trainingForm.hasValidationErrors()) {
        this.isLoading = false
        return true
      }

      let request
      this.training.files = null
      if (this.training.id) {
        request = ApiService.patchTraining(this.training)
      } else {
        request = ApiService.createTraining(this.training)
      }

      if (showMessage) {
        await request.then((request) => {
          if (request.status === 200) {
            if (this.training.id) {
              notification('Training succesvol aangepast!')
            } else {
              notification('Training succesvol toegevoegd!')
            }

            this.$router.push({ name: 'admin.trainings' })
          }
        })
      }

      this.isLoading = false
    }
  },
  computed: {
    total: function () {
      const offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      return {
        min: offsetMin,
        max: offsetMax > this.bookingsTotal ? this.bookingsTotal : offsetMax
      }
    },
    filteredEvents () {
      let events = this.training.events

      console.log(this.filterEventStart)
      if (this.filterEventStart) {
        events = events.sort((a, b) => DateTime.fromISO(a.event_start).toJSDate().getTime() < DateTime.fromISO(b.event_start).toJSDate().getTime() ? 1 : -1)
      } else {
        events = events.sort((a, b) => DateTime.fromISO(a.event_start).toJSDate().getTime() > DateTime.fromISO(b.event_start).toJSDate().getTime() ? 1 : -1)
      }

      return events
    }
  }
}
</script>
